<template>
  <div class="modal fade" id="addNewModal" tabindex="-1" aria-labelledby="addNewLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <Form @submit="submitForm">
          <div class="modal-header">
            <h1 class="modal-title fs-5">Add New Notifications</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6 mb-3">
                <label for="date" class="col-form-label fw-semibold">Publish Date and Time</label>
                  <Field name="date" :rules="{ required: true }" v-slot="{ field }">
                     <Datepicker1 class="datepicker-here form-control digits py-0" position="left" placeholder="Date"
                     v-model="date" v-bind="field" data-language="en" :min-date="new Date()"></Datepicker1>
                    <ErrorMessage name="date" class="text-danger"></ErrorMessage>
                  </Field>
              </div>
              <div class="col-md-6 mb-3">
                <label for="type" class="col-form-label fw-semibold">Type</label>
                <Field name="type" :rules="{ required: true }" v-slot="{ field }">
                  <multiselect v-model="selectedType" v-bind="field" :options="type" :searchable="false" :close-on-select="true" :show-labels="false" 
                  placeholder="Type" :allow-empty="false"></multiselect>
                  <ErrorMessage name="type" class="text-danger"></ErrorMessage>
                </Field>
              </div>
              <div class="col-md-12">
                <label for="notificationsTitle" class="col-form-label fw-semibold">Notifications Title</label>
                
                  <Field name="notificationsTitle" type="text" class="form-control" placeholder="Notifications Title" id="notificationsTitle" rules="required" v-model="title" />
                  <ErrorMessage name="notificationsTitle" class="text-danger" />
                
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Create</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import Datepicker from "vue3-datepicker";
import Datepicker1 from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  data() {
    return {
      type: ['official', 'events', 'specify', 'staff' ],
      selectedType: '',
      date: null,
      title: ''
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    Datepicker,
    Datepicker1
  },
  methods: {
    submitForm() {
      const api = `${process.env.VUE_APP_PATH}/notifactions/create`;
      this.axios.post(api, {
        publish_at: this.date,
        type: this.selectedType,
        title: this.title
      })
        .then((res) => {
          if (res.data.status_code === 'SYSTEM_1000') {
            this.$emit('update');
            this.$emit('hide');
            this.$toastSuccess('Added successfully');
          } else {
            const msg = checkStatusCode(res.data.status_code);
            this.$toastError(msg);
          }
        }).catch(error => {
          console.log('Error:', error);
        });
    },
  },
  emits: ['hide', 'update']
}
</script>

<template>
  <div class="modal fade" id="editServiceAreaModal" tabindex="-1" aria-labelledby="editServiceAreaLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5">Edit Service Area</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div v-for="(area, index) in localServiceAreas" :key="area.id" class="d-flex justify-content-between">
                <div class="checkbox checkbox-primary checkbox-center ms-2">
                  <input :id="'deleteArea' + index" type="checkbox" :true-value="1" :false-value="0"
                  v-model="area.deleted" @change="toggleDefault(index)">
                  <label :for="'deleteArea' + index">
                      <p class="mb-0">Service Area</p>
                      <p class="mb-0 fs-sm text-secondary">{{ area.city }} {{ area.state }} , {{ area.country }}</p>      
                  </label>
                </div>
                <div class="checkbox checkbox-primary ms-2">
                  <input :id="'default' + index" type="checkbox" :true-value="1" :false-value="0"
                  v-model="area.default" @change="toggleSelected(index)">
                  <label :for="'default' + index">Default</label>
                </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
              <button type="button" class="btn btn-secondary" @click="deleteServiceAreas" :disabled="!hasSelectedServiceAreas">Delete</button>
              <button type="button" class="btn btn-primary" @click="updateServiceAreas" :disabled="!hasSelectedDefaultAreas">Create</button>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
  props: ['serviceAreas'],
  data() {
    return {
      localServiceAreas: JSON.parse(JSON.stringify(this.serviceAreas)) // 深拷貝
    }
  },
  computed: {
    hasSelectedServiceAreas() {
      return this.localServiceAreas.some(area => area.deleted);
    },
    hasSelectedDefaultAreas() {
      return this.localServiceAreas.some(area => area.default);
    }
  },
  methods: {
    toggleDefault(index) {
      if (this.localServiceAreas[index].deleted) {
        this.localServiceAreas.forEach((area, i) => {
            area.default = false;
        });
      }
    },
    toggleSelected(index) {
      if (this.localServiceAreas[index].default) {
        this.localServiceAreas.forEach((area, i) => {
            area.deleted = false;
        });
      }
    },
    updateServiceAreas() {
      const api = `${process.env.VUE_APP_PATH}/staff/update_service_area`;
      const selectedAreas = this.localServiceAreas.filter(area => area.default).map(area => area.id);
      this.axios.post(api, {
        user_id: this.$route.params.staffId,
        service_area_ids: selectedAreas
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.$emit('getServiceAreas');
          this.$emit('hide');
          this.$toastSuccess('Service areas updated successfully');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    deleteServiceAreas() {
      const api = `${process.env.VUE_APP_PATH}/staff/delete_service_area`;
      const selectedAreas = this.localServiceAreas.filter(area => area.deleted).map(area => area.id);
      this.axios.post(api, {
        user_id: this.$route.params.staffId,
        service_area_ids: selectedAreas
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.$emit('getServiceAreas');
          this.$emit('hide');
          this.$toastSuccess('Service areas deleted successfully');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    }
  },
  emits: ['hide', 'getServiceAreas']
}
</script>

<style>

</style>
<template>
  <Breadcrumbs title="Update Detail"/>
  <div class="container-fluid">
    <div class="d-flex align-items-center gap-1 mb-5">
      <router-link :to="{ name: 'update' }" class="link-dark">
        <vue-feather stroke-width="3" type="arrow-left"></vue-feather>
      </router-link>
      <h5 class="mb-0">Ver {{ updateDetail.version }}</h5>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between align-items-center mb-4">
              <p class="text-secondary fs-6 mb-0">Ver {{ updateDetail.version }}</p>
              <div v-if="!isPublishDatePassed" class="fw-semibold">
                <vue-feather type="edit" size="18" class="me-2 text-primary"></vue-feather>
                <a href="javascript:;" class="fs-6 text-primary" @click="showModal('editNote')">Edit</a>
              </div>
            </div>
            <div class="row g-4">
              <div class="col-md-6">
                <div class="d-flex align-items-center gap-2">
                  <img class="img-40 rounded-circle" :src="userData.image ? userData.image : 'https://fakeimg.pl/40x40/?text=40x40'" alt="profile">
                  <div>
                    <h6 class="mb-0">{{ userData.first_name }} {{ userData.last_name }}</h6>
                    <p class="mb-0 text-secondary">{{ userData.mail_address }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <h6 class="mb-0">Update type</h6>
                <p class="mb-0 text-secondary">{{ updateDetail.update_type }}</p>
              </div>
              <div class="col-md-6">
                <h6 class="mb-0">Device</h6>
                <p class="mb-0 text-secondary">{{ updateDetail.device }}</p>
              </div>
              <div class="col-md-6">
                <h6 class="mb-0">F/W</h6>
                <p class="mb-0 text-secondary">{{ updateDetail.type }}</p>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="mb-3">
              <p class="fs-6 mb-0">Note</p>
              <p class="text-secondary mb-0">{{ updateDetail.note }}</p>
            </div>
            <div class="mb-3">
              <p class="fs-6 mb-0">Created at</p>
              <p class="text-secondary mb-0">{{ updateDetail.created_at }}</p>
            </div>
             <div class="mb-3">
              <p class="fs-6 mb-0">Publish at</p>
              <p class="text-secondary mb-0">{{ updateDetail.publish_at }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card mb-4">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center mb-4">
              <h5 class="text-secondary mb-0">Update information</h5>
              <div v-if="!isPublishDatePassed" class="fw-semibold">
                <vue-feather type="edit" size="18" class="me-2 text-primary"></vue-feather>
                <a href="javascript:;" class="fs-6 text-primary" @click="showModal('editInfo')">Edit</a>
              </div>
            </div>
            <p class="content-box text-secondary">{{ updateDetail.content }}</p>
            <div>
              <p class="fs-6 mb-0">Upload File</p>
              <p class="text-secondary mb-0">{{ updateDetail.file_name }}  {{ updateDetail.file_size }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <editNote ref="editNote" v-if="shown.editNote" :detail="updateDetail"
  @hide="hideModal('editNote')" @update="getUpdateDetail()"></editNote>
  <editInfo ref="editInfo" v-if="shown.editInfo" :detail="updateDetail"
  @hide="hideModal('editInfo')" @update="getUpdateDetail()"></editInfo>
</template>

<script>
import Modal from "bootstrap/js/dist/modal";
import editNote from '../../components-gc/modal/setting/update/editNote.vue';
import editInfo from '../../components-gc/modal/setting/update/editInfo.vue';
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
  data() {
    return {
      updateDetail: {},
      userData: {
        id: 2400000000,
        first_name: "first",
        last_name: "last",
        image: "https://fakeimg.pl/40x40/?text=40x40",
        mail_address: "google@gmail.com",
      },
      shown: {
        editNote: false,
        editInfo: false,
      },
      modals: {},
      
    };
  },
  components: {
    editNote,
    editInfo,
  },
  mounted() {
    this.getUpdateDetail();
  },
  methods: {
    showModal(name) {
      this.shown[name] = true;
      this.$nextTick(() => {
        this.modals[name] = new Modal(`#${name}Modal`);
        this.modals[name].show();
        this.$refs[name].$el.addEventListener('hidden.bs.modal', () => this.onHidden(name));
      });
    },
    hideModal(name) {
      this.modals[name].hide();
    },
    onHidden(name) {
      this.shown[name] = false;
      this.$refs[name].$el.removeEventListener('hidden.bs.modal', () => this.onHidden(name));
    },
    getUpdateDetail() {
      const api = `${process.env.VUE_APP_PATH}/update/detail`;
      this.axios.post(api, { id: this.$route.params.updateId })
        .then((res) => {
          const msg = checkStatusCode(res.data.status_code);
          if (res.data.status_code === 'SYSTEM_1000') {
            this.updateDetail = res.data.detail;
          } else if(res.data.status_code === 'SYSTEM_0013') {
              this.$toastWarning(`${msg} You will be redirected to the list.`);
              setTimeout(() => {
                this.$router.push({ name: 'update' });
              }, 4000);
            } else {
            this.$toastError(msg);
          }
        }).catch(error => {
          console.log('Error:', error);
        });
    },
  },
  computed: {
    isPublishDatePassed() {
      const publishDate = new Date(this.updateDetail.publish_at);
      const currentDate = new Date();
      return publishDate <= currentDate;
    }
  }
};
</script>

<style scoped>
.content-box {
  max-height: 800px;
  overflow: auto;
}
</style>
<template>
  <div class="modal fade" id="audioModal" tabindex="-1" aria-labelledby="audioModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content border-0">
        <audio :src="audioFile" controls preload="auto">
          Your browser does not support the audio element.
        </audio>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['audioFile'],
  // mounted() {
  //   console.log(this.audioFile);
  // }
}
</script>
<template>
  <div class="modal fade" id="addServiceAreaModal" tabindex="-1" aria-labelledby="addServiceAreaLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <Form @submit="submitForm">
          <div class="modal-header">
            <h1 class="modal-title fs-5">Add Service Area</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <div class="col-md-4">
                <label class="col-form-label fw-semibold">Country</label>
                <Field name="country" :rules="{ required: true }" v-slot="{ field }">
                  <multiselect 
                    v-model="listRequest.country" 
                    :options="options.country" 
                    :searchable="false" 
                    :close-on-select="true" 
                    :show-labels="false" 
                    placeholder="Country" 
                    v-bind="field"
                    @update:modelValue="selectChange('country')">
                  </multiselect>
                  <ErrorMessage name="country" class="text-danger"></ErrorMessage>
                </Field>
              </div>
              <div class="col-md-4">
                <label class="col-form-label fw-semibold">State</label>
                <Field name="state" :rules="{ required: true }" v-slot="{ field }">
                  <multiselect 
                    v-model="listRequest.state" 
                    :options="options.state" 
                    :searchable="false" 
                    :close-on-select="true" 
                    :show-labels="false" 
                    placeholder="State" 
                    v-bind="field"
                    @update:modelValue="selectChange('state')">
                  </multiselect>
                  <ErrorMessage name="state" class="text-danger"></ErrorMessage>
                </Field>
              </div>
              <div class="col-md-4">
                <label class="col-form-label fw-semibold">City</label>
                <Field name="city" :rules="{ required: true }" v-slot="{ field }">
                  <multiselect 
                    v-model="listRequest.city" 
                    :options="options.city" 
                    :searchable="false" 
                    :close-on-select="true" 
                    :show-labels="false" 
                    placeholder="City" 
                    v-bind="field"
                    @update:modelValue="selectChange('city')">
                  </multiselect>
                  <ErrorMessage name="city" class="text-danger"></ErrorMessage>
                </Field>
              </div>
            </div>

            <div class="checkbox checkbox-primary ms-2">
              <input id="default" type="checkbox" v-model="defaultVal">
              <label for="default">Default</label>
            </div>

            <small class="text-secondary">Set default area will showing staff in assign list with higher priority.</small>
                
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Create</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
  data() {
    return {
      options: {
        country: [],
        state: [],
        city: [],
      },
      listRequest: {
        country: '',
        state: '',
        city: ''
      },
      defaultVal: false
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    getCountry() {
      const api = `${process.env.VUE_APP_PATH}/global/countries`;
      this.axios.get(api, {
        params: { type: 'house' }
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.options.country = res.data.countries;
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    getState() {
      const api = `${process.env.VUE_APP_PATH}/global/states`;
      this.axios.get(api, {
        params: { type: 'house', country: this.listRequest.country }
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.options.state = res.data.states;
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    getCity() {
      const api = `${process.env.VUE_APP_PATH}/global/cities`;
      this.axios.get(api, {
        params: { type: 'house', country: this.listRequest.country, state: this.listRequest.state }
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.options.city = res.data.cities;
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    selectChange(type) {
      if (type === 'country') {
        this.listRequest.state = '';
        this.listRequest.city = '';
        this.getState();
      } else if (type === 'state') {
        this.listRequest.city = '';
        this.getCity();
      }
    },
    submitForm() {
      const api = `${process.env.VUE_APP_PATH}/staff/add_service_area`;
      this.axios.post(api, {
        user_id: this.$route.params.staffId,
        country: this.listRequest.country,
        state: this.listRequest.state,
        city: this.listRequest.city,
        default: this.defaultVal
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.$emit('getServiceAreas');
          this.$emit('hide');
          this.$toastSuccess('Service area added successfully');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
  },
  mounted() {
    this.getCountry();
  },
  emits: ['hide', 'getServiceAreas']
}
</script>
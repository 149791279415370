<template>
  <Breadcrumbs title="role setting" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <div class="d-flex gap-3">
            <div class="select-wrap">
              <multiselect 
                :options="options.roles" 
                :searchable="false" 
                @update:modelValue="(val) => selectChange('role', val)"
                :close-on-select="true" 
                :show-labels="false" 
                placeholder="Role" 
                id="role"
                track-by="id"
                label="name"
                v-model="selected.role"
              >
              </multiselect>
            </div>
            <div class="select-wrap">
              <multiselect v-model="selected.status" :options="options.status" :searchable="false"
                :close-on-select="true" :show-labels="false" placeholder="Status" id="status"
                @update:modelValue="(val) => selectChange('status', val)">
              </multiselect>
            </div>
            <button type="button" class="btn btn-primary" @click.prevent="getList">Search</button>
          </div>
          <div class="d-flex gap-3">
            <div class="position-relative align-self-center search-box">
              <input type="text" class="form-control" placeholder="Search" v-model="listRequest.search">
              <vue-feather type="search" size="20" class="position-absolute"></vue-feather>
            </div>
            <button type="button" class="btn btn-primary" @click.prevent="showModal('addNew')">+ Add New</button>
          </div>
        </div>
        <div class="card">
          <div class="table-responsive">
            <table class="table align-middle">
              <thead>
                <tr>
                  <th scope="col">Index</th>
                  <th scope="col">Role</th>
                  <th scope="col">Permissions</th>
                  <th scope="col" @click="sortList('account')" class="cursor-p">
                    <div class="d-flex justify-content-between align-items-center gap-2">
                      <p class="mb-0">Account</p>
                      <div>
                        <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('account') && listRequest.order === 'asc' }"></i>
                        <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('account') && listRequest.order === 'desc' }"></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col" @click="sortList('status')" class="cursor-p">
                    <div class="d-flex justify-content-between align-items-center gap-2">
                      <p class="mb-0">Status</p>
                      <div>
                        <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('status') && listRequest.order === 'asc' }"></i>
                        <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('status') && listRequest.order === 'desc' }"></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col" @click="sortList('created_at')" class="cursor-p">
                    <div class="d-flex justify-content-between align-items-center gap-2">
                      <p class="mb-0">Created at</p>
                      <div>
                        <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('created_at') && listRequest.order === 'asc' }"></i>
                        <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('created_at') && listRequest.order === 'desc' }"></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in list" :key="item.id">
                  <td>#{{ item.id }}</td>
                  <td>{{ item.name }}</td>
                  <td>
                    <div v-if="item.permissions" class="ms-3 text-dark">
                      <template v-for="permission in item.permissions" :key="permission.id">
                        <p v-if="permission.enabled" class="mb-0">{{ permission.name }}</p>
                        <div v-if="permission.children && permission.children.length > 0" class="submenu-box position-relative">
                          <template v-for="child in permission.children" :key="child.id">
                            <p v-if="child.enabled"   class="mb-0">{{ child.name }}</p>
                          </template>  
                        </div>
                      </template>
                    </div>
                  </td>
                  <td>{{ item.account }} Active</td>
                  <td>
                    <div class="d-flex gap-2 align-items-center">
                      <label :class="['form-check-label', 'mb-0', { 'txt-primary': item.status }]">{{ item.status ? 'Enabled' : 'Disabled' }}</label>
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" v-model="item.status" :true-value="1" :false-value="0" 
                        @click.prevent="confirmSwitch(item)">
                      </div>
                    </div>
                  </td>
                  <td>{{ item.created_at }}</td>
                  <td>
                    <router-link :to="{ name: 'roleSettingDetail',  params: { roleId: item.id } }"
                    @click="setRoleName(item.name)"
                    >Details</router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <Pagination :total="total" :currentPage.sync="listRequest.page" :limit="listRequest.limit" @updatePage="updatePage" />
      </div>
    </div>
  </div>
  <addNew v-if="shown.addNew" ref="addNew" @hide="hideModal('addNew')" @update="() => { this.getList(); this.getRole(); }" />
  <SwitchCheck v-if="shown.switchCheck" ref="switchCheck" :text="text" @handleSwitch="switchStatus" @hide="hideModal('switchCheck')" />
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from "bootstrap/js/dist/modal";
import addNew from '@/components-gc/modal/setting/roleSetting/addNew.vue';
import SwitchCheck from '@/components-gc/modal/switchCheck.vue';
import Pagination from '@/components-gc/Pagination.vue';
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
  data() {
    return {
      list: [],
      listRequest: {
        role_id: '',
        enabled: '',
        page: 1,
        limit: 10,
        order_by: "",
        order: "",
        search: '',
      },
      total: 0,
      options: {
        status: ['Enabled', 'Disabled'],
        roles: []
      },
      selected: {
        status: '',
        role: ''
      },
      modals: {},
      shown: {
        addNew: false,
        switchCheck: false
      },
      switchItem: null
    };
  },
  components: {
    addNew,
    Pagination,
    SwitchCheck
  },
  mounted() {
    this.getList();
    this.getRole();
  },
  methods: {
    ...mapMutations('user', ['setRoleName']),
    getRole() {
      const api = `${process.env.VUE_APP_PATH}/global/roles`;
      this.axios.get(api)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.options.roles = res.data.roles;
        } else {
          console.log('Error:', res.data.status_code);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    getList() {
      const api = `${process.env.VUE_APP_PATH}/role/list`;
      this.axios.post(api, this.listRequest)
        .then((res) => {
          if (res.data.status_code === 'SYSTEM_1000') {
            this.list = res.data.data;
            this.total = res.data.total;
          } else {
            const msg = checkStatusCode(res.data.status_code);
            this.$toastError(msg);
          }
        }).catch(error => {
          console.log('Error:', error);
        });
    },
    sortList(column) {
      if (this.listRequest.order_by === column) {
        this.listRequest.order = this.listRequest.order === 'asc' ? 'desc' : 'asc';
      } else {
        this.listRequest.order_by = column;
        this.listRequest.order = 'asc';
      }
      this.getList();
    },
    isActiveSort(column) {
      return this.listRequest.order_by === column;
    },
    updatePage(page) {
      this.listRequest.page = page;
      this.getList();
    },
    showModal(name) {
      this.shown[name] = true;
      this.$nextTick(() => {
        this.modals[name] = new Modal(this.$refs[name].$el);
        this.modals[name].show();
        this.$refs[name].$el.addEventListener('hidden.bs.modal', () => this.onHidden(name));
      });
    },
    hideModal(name) {
      this.modals[name].hide();
    },
    onHidden(name) {
      this.shown[name] = false;
      this.$refs[name].$el.removeEventListener('hidden.bs.modal', () => this.onHidden(name));
    },
    selectChange(type, val) {
      if (val) {
        switch (type) {
          case 'role':
            this.listRequest.role_id = val.id;
            break;
          case 'status':
            this.listRequest.enabled = val === 'Enabled' ? 'Y' : val === 'Disabled' ? 'N' : '';
            break;
        }
      } else {
        this.listRequest[type === 'role' ? 'role_id' : 'enabled'] = '';
      }
    },
    confirmSwitch(item) {
      this.switchItem = item;
      this.showModal('switchCheck');
    },
    switchStatus() {
      this.hideModal('switchCheck');
      const api = `${process.env.VUE_APP_PATH}/role/switch`;
      this.axios.post(api, {
        role_id: this.switchItem.id,
        enable: !this.switchItem.status
      })
      .then((res) => {
        if (res.data.status_code !== 'SYSTEM_1000') {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        } else {
          this.getList();
          this.$toastSuccess('Status updated successfully');
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    }
  },
   watch: {
      'listRequest.search'(newVal, oldVal) {
      if(newVal !== oldVal) {
        this.getList();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.submenu-box {
  margin-left: 6px;
}
.submenu-box::before {
  content: '';
  width: 1px;
  height: calc(100% - 11px);
  background-color: var(--bs-gray-500);
  position: absolute;
  left: 0;
  top: 0;
}
.submenu-box p::before {
  content: '';
  display: inline-block;
  width: 15px;
  height: 1px;
  background-color: var(--bs-gray-500);
  margin-right: 10px;
  vertical-align: middle;
}
</style> 
import axios from 'axios';
import { checkStatusCode } from '@/methods-gc/statusCode';

const API_PATH = process.env.VUE_APP_PATH; 

export function getQRcode(id, apiType = null) {
  const apiPath = apiType ? `/device/download_qrcode` : '/house/download_qrcode';
  const params = apiType ? {
    device_id: id,
    type: apiType
  } :
  {
    house_id: id,
  }
  return axios.post(`${API_PATH}${apiPath}`, params)
    .then((res) => {
      if (res.data.status_code === 'SYSTEM_1000') {
        return {
          qrcodeImg: res.data.image,
          verifyCode:  res.data.verify_code
        };
      } else {
        const msg = checkStatusCode(res.data.status_code);
        throw new Error(msg);
      }
    });
}

export function createQRImg(qrcodeImg, verifyCode = null) {
  return new Promise((resolve, reject) => {
    if (qrcodeImg) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.src = `data:image/png;base64,${qrcodeImg}`;

      img.onload = () => {
        canvas.width = img.width;
        canvas.height = verifyCode ? img.height + 50 : img.height; // 如果有 verifyCode，增加高度

        ctx.drawImage(img, 0, 0);
        if (verifyCode) {
          ctx.font = '20px Arial';
          ctx.fillStyle = 'black';
          ctx.textAlign = 'center';
          ctx.fillText(verifyCode, canvas.width / 2, img.height + 30); // 在 QR Code 下方顯示 verifyCode
        }

        resolve(canvas.toDataURL('image/png'));
      };

      img.onerror = () => {
        reject(new Error('Failed to load image'));
      };
    } else {
      reject(new Error('QR Code can not be downloaded'));
    }
  });
}

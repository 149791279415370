<template>
  <div class="modal fade" id="editProfileModal" tabindex="-1" aria-labelledby="editProfileLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <Form ref="form" @submit="submitForm">
          <div class="modal-header">
            <h1 class="modal-title fs-5">Edit</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col col-12 col-md-3 mb-3">
                <label class="col-form-label fw-semibold">Profile Image</label>
                <img class="img-fluid w-100" :src="localUser && localUser.profile ? localUser.profile : 'https://fakeimg.pl/500x500/?text=image'" alt="profile">
              </div>
              <div class="col col-12 col-md-9 mb-3">
                <div class="row">
                  <div class="col col-md-6 mb-5">
                    <label for="firstName" class="col-form-label fw-semibold">First Name</label>
                    <Field v-model="localUser.first_name" name="firstName" type="text" class="form-control" placeholder="First Name" id="firstName" />
                    <ErrorMessage name="firstName" class="text-danger" />
                  </div>
                  <div class="col col-12 col-md-6 mb-5">
                    <label for="lastName" class="col-form-label fw-semibold">Last Name</label>
                    <Field v-model="localUser.last_name" name="lastName" type="text" class="form-control" placeholder="Last Name" id="lastName" />
                    <ErrorMessage name="lastName" class="text-danger" />
                  </div>
                  <div class="col col-12 col-md-12">
                    <label class="col-form-label fw-semibold">Role</label>
                    <Field name="role" :rules="{ required: true }" v-slot="{ field }">
                      <multiselect 
                      v-bind="field"
                      :options="rolesOptions" 
                      :searchable="false" 
                      :close-on-select="true" 
                      :show-labels="false"
                      @update:modelValue="selectChange"
                      track-by="id"
                      label="name" 
                      placeholder="Role" 
                      v-model="localRole"
                      ></multiselect>
                      <ErrorMessage name="role" class="text-danger"></ErrorMessage>
                    </Field>
                  </div>
                </div>
              </div>
              <div class="col col-12 col-md-6 mb-3">
                <label for="email" class="col-form-label fw-semibold">Email</label>
                <p class="text-secondary mb-0">{{ localUser.email }}</p>
              </div>
              <div class="col col-12 col-md-6 mb-3">
                <label for="password" class="col-form-label fw-semibold">Password</label>
                <Field v-model="localUser.password" name="password" type="password" class="form-control" placeholder="Password" id="password" />
                <ErrorMessage name="password" class="text-danger" />
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
              <button type="button" class="btn btn-secondary me-2" @click.prevent="handleDelete">Delete Account</button>
              <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import Multiselect from 'vue-multiselect';
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
  props: ['user', 'roleName', 'roleId'],
  data() {
    return {
      localUser: { ...this.user },
      localRole: { id: this.roleId, name: this.roleName },
      rolesOptions: [],
      role_id: this.roleId,
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  mounted() {
    this.getRoles();
    this.$nextTick(() => {
      this.$refs.form.setFieldValue('role', this.localRole);
    });
  },
  methods: {
    getRoles() {
      const api = `${process.env.VUE_APP_PATH}/global/roles`;
      this.axios.get(api)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.rolesOptions = res.data.roles;
        } else {
          console.log('Error:', res.data.status_code);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    submitForm() {
      const api = `${process.env.VUE_APP_PATH}/administrator/update`;
      const payload = {
        admin_id: this.localUser.id,
        first_name: this.localUser.first_name,
        last_name: this.localUser.last_name,
        role_id: this.role_id
      };

      this.axios.post(api, payload)
        .then((res) => {
          if (res.data.status_code === 'SYSTEM_1000') {
            this.$emit('update');
            this.$emit('hide');
            this.$toastSuccess('Updated successfully');
          } else {
            const msg = checkStatusCode(res.data.status_code);
            this.$toastError(msg);
          }
        }).catch(error => {
          console.log('Error:', error);
        });
    },
     handleDelete() {
      const api = `${process.env.VUE_APP_PATH}/administrator/delete`;
      const payload = {
        admin_id: this.localUser.id
      };

      this.axios.post(api, payload)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.$toastSuccess('Deleted successfully, You will be redirected to the list');
          setTimeout(() => {
            this.$emit('hide');
            this.$emit('update');
          }, 4000);
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    selectChange(val) {
      console.log(val)
      if(val) {
        this.role_id = val.id;
      }
    },
  },
  emits: ['hide', 'update']
};
</script>
<template>
  <Breadcrumbs title="FAQ"/>
  <div class="container-fluid">
    <div class="d-flex align-items-center gap-1 mb-5">
      <router-link :to="{ name: 'faq' }" class="link-dark">
        <vue-feather stroke-width="3" type="arrow-left"></vue-feather>
      </router-link>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between align-items-center mb-4">
              <p class="text-secondary fs-6 mb-0"># {{ faqDetail.id }}</p>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div v-if="faqDetail.creator" class="d-flex align-items-center gap-2">
                  <img class="img-40 rounded-circle" :src="faqDetail.creator.profile || 'https://fakeimg.pl/40x40/?text=40x40'" alt="profile">
                  <div>
                    <h6 class="mb-0">{{ faqDetail.creator.first_name }} {{ faqDetail.creator.last_name }}</h6>
                    <p class="mb-0 text-secondary">{{ faqDetail.creator.email }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <h6 class="mb-0">Created at</h6>
                <p class="mb-0 text-secondary">{{ faqDetail.created_at }}</p>
              </div>
            </div>
          </div>
          <div class="card-body">
            <p class="fs-6">Title</p>
            <p class="text-secondary mb-0">{{ faqDetail.title }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card mb-4">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center mb-4">
              <h5 class="text-secondary">Contents</h5>
              <div class="fw-semibold">
                <vue-feather type="edit" size="18" class="me-2 text-primary"></vue-feather>
                <a href="javascript:;" class="text-primary" @click="showModal('editContent')">Edit</a>
              </div>
            </div>
            <div class="content-box mb-0" v-html="faqDetail.content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <editContent ref="editContent" v-if="shown.editContent" :detail="faqDetail"
  @hide="hideModal('editContent')" @update="getFaqDetail()"></editContent>
</template>

<script>
import Modal from "bootstrap/js/dist/modal";
import editContent from '@/components-gc/modal/setting/faq/editContent.vue';
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
  data() {
    return {
      faqDetail: {},
      shown: {
        editContent: false,
      },
      modals: {},
    };
  },
  components: {
    editContent,
  },
  mounted() {
    this.getFaqDetail();
  },
  methods: {
    showModal(name) {
      this.shown[name] = true;
      this.$nextTick(() => {
        this.modals[name] = new Modal(this.$refs[name].$el);
        this.modals[name].show();
        this.$refs[name].$el.addEventListener('hidden.bs.modal', () => this.onHidden(name));
      });
    },
    hideModal(name) {
      this.modals[name].hide();
    },
    onHidden(name) {
      this.shown[name] = false;
      if(this.$refs[name]) {
        this.$refs[name].$el.removeEventListener('hidden.bs.modal', () => this.onHidden(name));
      }
    },
    getFaqDetail() {
      const api = `${process.env.VUE_APP_PATH}/faq/detail`;
      this.axios.post(api, { id: this.$route.params.faqId })
        .then((res) => {
          const msg = checkStatusCode(res.data.status_code);
          if (res.data.status_code === 'SYSTEM_1000') {
            this.faqDetail = res.data.detail;
            } else if(res.data.status_code === 'SYSTEM_0019') {
              this.$toastWarning(`${msg} You will be redirected to the list.`);
              setTimeout(() => {
                this.$router.push({ name: 'faq' });
              }, 4000);
            } else {
            this.$toastError(msg);
          }
        }).catch(error => {
          console.log('Error:', error);
        });
    },
  },
};
</script>

<style scoped>
.content-box {
  max-height: 800px;
  overflow: auto;
}
</style>
<template>
  <div class="modal fade" id="addNewModal" tabindex="-1" aria-labelledby="addNewLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <Form @submit="submitForm">
          <div class="modal-header">
            <h1 class="modal-title fs-5">Add New Administrator</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col col-md-3 mb-3">
                <label class="col-form-label fw-semibold">Profile Image</label>
                <img class="img-fluid w-100" :src="profile ? profile : 'https://fakeimg.pl/500x500/?text=image'" alt="profile">
              </div>
              <div class="col col-md-9 mb-3">
                <div class="row">
                  <div class="col col-md-6 mb-5">
                    <label for="firstName" class="col-form-label fw-semibold">First Name</label>
                    <Field v-model="firstName" name="firstName" type="text" class="form-control" placeholder="First Name" id="firstName"  />
                    <ErrorMessage name="firstName" class="text-danger" />
                  </div>
                  <div class="col col-md-6 mb-5">
                    <label for="lastName" class="col-form-label fw-semibold">Last Name</label>
                    <Field v-model="lastName" name="lastName" type="text" class="form-control" placeholder="Last Name" id="lastName" />
                    <ErrorMessage name="lastName" class="text-danger" />
                  </div>
                  <div class="col-md-12">
                    <label class="col-form-label fw-semibold">Role</label>
                    <Field name="role" :rules="{ required: true }" v-slot="{ field }">
                      <multiselect 
                          v-bind="field"
                          :options="rolesOptions" 
                          :searchable="false" 
                          :close-on-select="true" 
                          :show-labels="false" 
                          @update:modelValue="selectChange"
                          placeholder="Role" 
                          id="role"
                          track-by="id"
                          label="name"
                          v-model="roleVal"
                        >
                      </multiselect>
                      <ErrorMessage name="role" class="text-danger"></ErrorMessage>
                    </Field>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="email" class="col-form-label fw-semibold">Email</label>
                <Field v-model="email" name="email" type="email" class="form-control" placeholder="Email" id="email" rules="required" />
                <ErrorMessage name="email" class="text-danger" />
              </div>
              <div class="col-md-6 mb-3">
                <label for="password" class="col-form-label fw-semibold">Password</label>
                <Field v-model="password" name="password" type="password" class="form-control" placeholder="Password" id="password" rules="required" />
                <ErrorMessage name="password" class="text-danger" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Create</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import Multiselect from 'vue-multiselect';
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      profile: null,
      role_id: null,
      roleVal: null,
      rolesOptions: [],
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  mounted() {
    this.getRoles();
  },
  methods: {
    getRoles() {
      const api = `${process.env.VUE_APP_PATH}/global/roles`;
      this.axios.get(api)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.rolesOptions = res.data.roles;
        } else {
          console.log('Error:', res.data.status_code);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    submitForm() {
      const api = `${process.env.VUE_APP_PATH}/administrator/create`;
      
      const payload = {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        password: this.password,
        role_id: this.role_id
      };

      this.axios.post(api, payload)
        .then((res) => {
          if (res.data.status_code === 'SYSTEM_1000') {
            this.$emit('update');
            this.$emit('hide');
            this.$toastSuccess('Added successfully');
          } else {
            const msg = checkStatusCode(res.data.status_code);
            this.$toastError(msg);
          }
        }).catch(error => {
          console.log('Error:', error);
        });
    },
    selectChange(val) {
      console.log(val)
      if(val) {
        this.role_id = val.id;
      }
    },
  },
  emits: ['update', 'hide']
}
</script>
<template>
  <Breadcrumbs title="Administrator Detail"/>
  <div class="container-fluid">
    <div class="d-flex align-items-center gap-1 mb-5">
      <router-link :to="{ name: 'administrator' }" class="link-dark">
        <vue-feather stroke-width="3" type="arrow-left"></vue-feather>
      </router-link>
      <h5 class="mb-0">Profile</h5>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between align-items-center mb-4">
              <p class="text-secondary fs-6 mb-0">#{{ user.id }}</p>
              <div class="fw-semibold">
                <vue-feather type="edit" size="18" class="me-2 text-primary"></vue-feather>
                <a href="javascript:;" class="fs-6 text-primary" @click="showModal('editProfile')">Edit Profile</a>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="d-flex align-items-center gap-2">
                  <img class="img-40 rounded-circle" :src="user.profile || 'https://fakeimg.pl/40x40/?text=40x40'" alt="profile">
                  <div>
                    <h6 class="mb-0">{{ user.first_name }} {{ user.last_name }}</h6>
                    <p class="mb-0 text-secondary">{{ user.email }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <h6 class="mb-0">Role</h6>
                <p class="mb-0 text-secondary">{{ role.name }}</p>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="mb-3">
              <p class="fs-6 mb-0">Created at</p>
              <p class="text-secondary mb-0">{{ user.created_at }}</p>
            </div>
             <div class="mb-3">
              <p class="fs-6 mb-0">Updated at</p>
              <p class="text-secondary mb-0">{{ user.updated_at }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card mb-4">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center mb-3">
              <h6 class="text-secondary mb-0">Permissions</h6>
              <div class="d-flex gap-2 align-items-center">
                <label :class="['form-check-label', 'mb-0', { 'txt-primary': role.status }]">{{ role.status ? 'Enabled' : 'Disabled' }}</label>
                <div class="form-check form-switch">
                  <input v-model="role.status" :true-value="1" :false-value="0" 
                  class="form-check-input" type="checkbox" role="switch" disabled>
                </div>
              </div>
            </div>
            <p class="text-secondary mb-0 fs-6">Role : {{ role.name }}</p>
            <div v-if="role.permissions" class="ms-3 mb-3">
              <div v-for="permission in role.permissions" :key="permission.id" class="checkbox checkbox-primary">
                <input :id="permission.name" type="checkbox" :checked="permission.enabled" disabled>
                <label :for="permission.name">{{ permission.name }}</label>
                <div v-if="permission.children.length" class="submenu-box position-relative">
                  <div v-for="child in permission.children" :key="child.id" class="checkbox checkbox-primary">
                    <input :id="child.name" type="checkbox" :checked="child.enabled" disabled>
                    <label :for="child.name">{{ child.name }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <editProfile ref="editProfile" v-if="shown.editProfile" :user="user" :roleName="role.name" :roleId="role.id"
  @hide="hideModal('editProfile')" @update="getDetail"></editProfile>
</template>

<script>
import Modal from "bootstrap/js/dist/modal";
import editProfile from '../../components-gc/modal/setting/administrator/editProfile.vue';
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
  data() {
    return {
      user: {},
      role: {},
      shown: {
        editProfile: false,
      },
      modals: {},
    };
  },
  components: {
    editProfile,
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    showModal(name) {
      this.shown[name] = true;
      this.$nextTick(() => {
        this.modals[name] = new Modal(this.$refs[name].$el);
        this.modals[name].show();
        this.$refs[name].$el.addEventListener('hidden.bs.modal', () => this.onHidden(name));
      });
    },
    hideModal(name) {
      this.modals[name].hide();
    },
    onHidden(name) {
      this.shown[name] = false;
      if(this.$refs[name]) {
        this.$refs[name].$el.removeEventListener('hidden.bs.modal', () => this.onHidden(name));
      }
    },
    getDetail() {
      const api = `${process.env.VUE_APP_PATH}/administrator/detail`;
      this.axios.post(api, { admin_id: this.$route.params.administratorId })
        .then((res) => {
          const msg = checkStatusCode(res.data.status_code);
          if (res.data.status_code === 'SYSTEM_1000') {
            this.user = res.data.user;
            this.role = res.data.role;
          } 
         else if(res.data.status_code === 'SYSTEM_0011') {
            this.$toastWarning(`${msg} You will be redirected to the list.`);
              setTimeout(() => {
                this.$router.push({ name: 'administrator' });
              }, 4000);
          }
            else {
            this.$toastError(msg);
          }
        }).catch(error => {
          console.log('Error:', error);
        });
    },
  },
};
</script>

<style scoped>
.submenu-box {
  margin-left: 6px;
}
.submenu-box::before {
  content: '';
  width: 1px;
  height: calc(100% - 17px);
  background-color: var(--bs-gray-500);
  position: absolute;
  left: 0;
  top: 0;
}
.submenu-box .checkbox::before {
  content: '';
  display: inline-block;
  width: 25px;
  height: 1px;
  background-color: var(--bs-gray-500);
  margin-right: 15px;
  vertical-align: middle;
}
</style>
<template>
  <div class="modal fade" id="editContentModal" tabindex="-1" aria-labelledby="editContentLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <Form  @submit="submitForm">
          <div class="modal-header">
            <h1 class="modal-title fs-5">Edit</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 mb-3">
                <label for="title" class="col-form-label fw-semibold">Title</label>
                <Field v-model="localDetail.title" name="title" as="textarea" class="form-control" placeholder="Add text" id="title" rules="required" />
                <ErrorMessage name="title" class="text-danger" />
              </div>
              <div class="col-md-12 mb-3">
                <div>
                   <label for="content" class="col-form-label fw-semibold">Content</label>
                  <quill-editor v-model:value="localDetail.content" :options="editorOption" 
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
             <button type="button" class="btn btn-secondary me-2" @click.prevent="handleDelete">Delete</button>
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
  data() {
    return {
      editorOption: {
        placeholder: 'content',
      },
      localDetail: { ...this.detail }
    }
  },
  props: ['detail'],
  emits: ['hide', 'update'],
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    submitForm() {
      const api = `${process.env.VUE_APP_PATH}/faq/update`;
      const payload = {
        id: this.$route.params.faqId,
        title: this.localDetail.title,
        content: this.localDetail.content
      };

      this.axios.post(api, payload)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.$emit('update');
          this.$emit('hide');
          this.$toastSuccess('Updated successfully');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    handleDelete() {
      const api = `${process.env.VUE_APP_PATH}/faq/delete`;
      const payload = {
        id: this.$route.params.faqId
      };

      this.axios.post(api, payload)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.$toastSuccess('Deleted successfully, You will be redirected to the list');
          // setTimeout(() => {
          //   this.$emit('hide');
          //   this.$emit('update');
          // }, 4000);
          this.$emit('hide');
          this.$emit('update');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
  }
}
</script>
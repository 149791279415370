<template>
  <div class="modal fade" id="editProfileModal" tabindex="-1" aria-labelledby="editProfileLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <Form @submit="submitForm">
          <div class="modal-header">
            <h1 class="modal-title fs-5">Edit Profile</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row g-3">
              <div class="col col-12 col-md-3">
                <label class="col-form-label fw-semibold">Profile Image</label>
                <img class="img-fluid w-100" :src="localStaffData.profile ? localStaffData.profile : 'https://fakeimg.pl/500x500/?text=image'" alt="profile">
                <!-- <input class="upload-img" type="file" name="img" @change="updateImage"> -->
              </div>
              <div class="col col-12 col-md-9">
                <div class="row g-3">
                  <div class="col col-md-6 ">
                    <label for="firstName" class="col-form-label fw-semibold">First Name</label>
                    <div class="position-relative">
                      <Field name="firstName" type="text" class="form-control" placeholder="First Name" id="firstName" v-model="localStaffData.first_name" />
                      <ErrorMessage name="firstName" class="text-danger" />
                    </div>
                  </div>
                  <div class="col col-12 col-md-6 ">
                    <label for="lastName" class="col-form-label fw-semibold">Last Name</label>
                    <div class="position-relative">
                      <Field name="lastName" type="text" class="form-control" placeholder="Last Name" id="lastName" v-model="localStaffData.last_name" />
                      <ErrorMessage name="lastName" class="text-danger" />
                    </div>
                  </div>
                  <div class="col col-12 col-md-6 ">
                    <label class="col-form-label fw-semibold">Account</label>
                    <p class="mb-0 text-secondary">{{ localStaffData.email }}</p>
                  </div>
                  <div class="col col-12 col-md-6 ">
                    <label class="col-form-label fw-semibold">Created at</label>
                    <p class="mb-0 text-secondary">{{ localStaffData.created_at }}</p>
                  </div>
                </div>
              </div>
              <div class="col col-12 col-md-3 ">
                <label for="mobile" class="col-form-label fw-semibold">Mobile</label>
                <div class="position-relative">
                  <Field name="mobile" type="tel" class="form-control" placeholder="mobile" id="mobile" v-model="localStaffData.phone" />
                  <ErrorMessage name="mobile" class="text-danger" />
                </div>
              </div>
              <div class="col col-12 col-md-9 ">
                <div class="row g-3">
                  <div class="col-md-6">
                    <label for="phone" class="col-form-label fw-semibold">Local Phone</label>
                    <div class="position-relative">
                      <Field name="phone" type="tel" class="form-control" placeholder="Local Phone" id="phone" v-model="localStaffData.local_phone" />
                      <ErrorMessage name="phone" class="text-danger" />
                    </div>
                  </div>
                  <div class="col col-12 col-md-6 ">
                    <label for="profileImage" class="col-form-label fw-semibold">Upload Photo</label>
                    <div class="position-relative">
                      <input type="file" class="form-control" id="profileImage" ref="profileImage">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
  props: ['staffData'],
  data() {
    return {
      localStaffData: { ...this.staffData },
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    submitForm() {
      const api = `${process.env.VUE_APP_PATH}/staff/update`;
      const formData = new FormData();
      
      formData.append('user_id', this.localStaffData.user_id);
      formData.append('first_name', this.localStaffData.first_name);
      formData.append('last_name', this.localStaffData.last_name);
      formData.append('home_phone', this.localStaffData.local_phone);
      formData.append('phone', this.localStaffData.phone);
      formData.append('email', this.localStaffData.email);
      
      if (this.$refs.profileImage && this.$refs.profileImage.files[0]) {
        formData.append('profile', this.$refs.profileImage.files[0]);
      }

      this.axios.post(api, formData)
        .then((res) => {
          if (res.data.status_code === 'SYSTEM_1000') {
            this.$emit('getStaffData');
            this.$emit('hide');
            this.$toastSuccess('Profile updated successfully');
          } else {
            const msg = checkStatusCode(res.data.status_code);
            this.$toastError(msg);
          }
        }).catch(error => {
          console.log('Error:', error);
        });
    },
    // updateImage(e) {
    //   const file = e.target.files[0];
    //   if (file) {
    //     this.localStaffData.profile = URL.createObjectURL(file);
    //   }
    // },
  },
  emits: ['hide', 'getStaffData']
}
</script>

<!-- <style>
.upload-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  line-height: 93px;
  opacity: 0;
}
</style> -->
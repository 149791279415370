<template>
  <div class="modal fade" id="downloadQRModal" tabindex="-1" aria-labelledby="downloadQRLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <Form ref="form" @submit.prevent="handlePrint">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="changePasswordLabel">Download QR Code</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div id="printData" class="modal-body text-center">
            <img  :src="qrcodeImg" class="img-fluid" alt="QR Code">
          </div>
          <div class="modal-footer justify-content-between">
              <button type="button" class="btn btn-light" @click.prevent="$emit('hide')">Cancel</button>
              <button type="submit" class="btn btn-primary">Print</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['qrcodeImg'],
  emits: ['hide'],
  methods: {
    handlePrint() {
      const printContents = document.getElementById('printData').innerHTML;
      const printWindow = window.open('', '_blank');
      
      printWindow.document.open();
      printWindow.document.write(`
        <html>
          <head>
            <title>Print QR Code</title>
            <style>
            @media print {
              body { margin: 2rem; }
            }
            </style>
          </head>
          <body onload="window.print(); ">
            ${printContents}
          </body>
        </html>
      `);
      printWindow.document.close();
      setTimeout(function(){printWindow.close();},10);
    },
  },
}
</script>

<!-- <style>
@media print {
  body > *:not(#printData, .modal,) {
    display: none;
  }
  #printData, #printData * {
    display: block;
  }
}
</style> -->

<template>
  <Breadcrumbs title="Notifications" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <div class="d-flex gap-3">
            <!-- <div class="select-wrap">
              <multiselect v-model="selected.type" :options="options.type" :searchable="false"
                :close-on-select="true" :show-labels="false" placeholder="Type">
              </multiselect>
            </div> -->
            <div class="select-wrap">
              <multiselect v-model="selected.status" :options="options.status" :searchable="false"
                :close-on-select="true" :show-labels="false" placeholder="Status">
              </multiselect>
            </div>
            <button type="button" class="btn btn-primary" @click.prevent="handleSearch">Search</button>
          </div>
          <div class="d-flex gap-3">
            <div class="position-relative align-self-center search-box">
              <input type="text" class="form-control" placeholder="Search" v-model="listRequest.search">
              <vue-feather type="search" size="20" class="position-absolute"></vue-feather>
            </div>
            <button type="button" class="btn btn-primary" @click.prevent="showModal('addNew')">+ Add New</button>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <ul class="nav nav-tabs border-tab" id="top-tab" role="tablist">
        <li class="nav-item">
          <a href="javascript:;" class="nav-link" :class="[listRequest.type === 'official' ? 'txt-primary active' : '']" @click.prevent="changeTab('official')">
            Home Page
          </a>
        </li>
        <li class="nav-item">
          <a href="javascript:;" class="nav-link" :class="[listRequest.type === 'events' ? 'txt-primary active' : '']" @click.prevent="changeTab('events')">
            Events
          </a>
        </li>
        <li class="nav-item">
          <a href="javascript:;" class="nav-link" :class="[listRequest.type === 'specify' ? 'txt-primary active' : '']" @click.prevent="changeTab('specify')">
            Specify
          </a>
        </li>
        <li class="nav-item">
          <a href="javascript:;" class="nav-link" :class="[listRequest.type === 'staff' ? 'txt-primary active' : '']" @click.prevent="changeTab('staff')">
            Staff
          </a>
        </li>
      </ul>

      <div v-if="data.length > 0" class="table-responsive">
        <table class="table align-middle">
          <thead>
            <tr>
              <th scope="col">Index</th>
              <th scope="col">Title</th>
              <th scope="col">Editor</th>
              <th scope="col">Status</th>
              <th scope="col">Link</th>
              <th scope="col" @click="sortList('created_at')" class="cursor-p">
                <div class="d-flex justify-content-between align-items-center gap-2">
                  <p class="mb-0">Create at</p>
                  <div>
                    <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('created_at') && listRequest.order === 'asc' }"></i>
                    <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('created_at') && listRequest.order === 'desc' }"></i>
                  </div>
                </div>
              </th>
              <th scope="col" @click="sortList('publish_at')" class="cursor-p">
                <div class="d-flex justify-content-between align-items-center gap-2">
                  <p class="mb-0">Publish at</p>
                  <div>
                    <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('publish_at') && listRequest.order === 'asc' }"></i>
                    <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('publish_at') && listRequest.order === 'desc' }"></i>
                  </div>
                </div>
              </th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in data" :key="item.id">
              <td>{{ item.id }}</td>
              <td class="fw-semibold">{{ item.title }}</td>
               <td >
                  <div v-if="item.editor" class="d-flex align-items-center gap-2">
                    <img class="img-40 rounded-circle" :src="item.editor.profile ? item.editor.profile : 'https://fakeimg.pl/40x40/?text=40x40'" alt="profile">
                    <div>
                      <p class="mb-0 text-dark">{{ item.editor.first_name }} {{ item.editor.last_name }}</p>
                      <p class="mb-0">{{ item.editor.email }}</p>
                    </div>
                  </div>
                </td>
              <td>
                <p class="mb-0 fs-sm fw-semibold" :class="{
                    'txt-primary': item.status === 'published',
                    'text-secondary': item.status === 'waiting'
                  }">
                   {{ $capitalizeFirst(item.status) }}
                </p>
              </td>
              <td>
                <a :href="item.url">Link</a>
              </td>
              <td>{{ item.created_at }}</td>
              <td>{{ item.publish_at }}</td>
              <td>
              <div>
                <router-link :to="{ name: 'notificationsDetail', params: { notificationsId: item.id } }" class="link-primary">Detail</router-link>
              </div>
            </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="text-center">
        <p>No data</p>
      </div>
    </div>
    <Pagination :total="total" :currentPage.sync="listRequest.page" :limit="listRequest.limit" @updatePage="updatePage" />
  </div>
  <addNew ref="addNew" v-if="shown.addNew" @hide="hideModal('addNew')" @update="getList" />
</template>

<script>
import Modal from "bootstrap/js/dist/modal";
import addNew from '@/components-gc/modal/notifications/addNew.vue';
import Pagination from '@/components-gc/Pagination.vue';
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
  data() {
    return {
      options: {
        type: ['official', 'events', 'specify', 'staff'],
        status: ['waiting', 'published'],
      },
      selected: {
        type: '',
        status: '',
      },
      modals: {},
      shown: {
        addNew: false,
      },
      data: [],
      listRequest: {
        type: "official",
        status: "",
        search: "",
        order_by: "",
        order: "",
        page: 1,
        limit: 10,
      },
      total: 0,
    };
  },
  components: {
    addNew,
    Pagination
  },
  mounted() {
    this.getList();
  },
  methods: {
    showModal(name) {
      this.shown[name] = true;
      this.$nextTick(() => {
        this.modals[name] = new Modal(this.$refs[name].$el);
        this.modals[name].show();
        this.$refs[name].$el.addEventListener('hidden.bs.modal', () => this.onHidden(name));
      });
    },
    hideModal(name) {
      this.modals[name].hide();
    },
    onHidden(name) {
      this.shown[name] = false;
      this.$refs[name].$el.removeEventListener('hidden.bs.modal', () => this.onHidden(name));
    },
    changeTab(tab) {
      this.listRequest.type = tab;
      this.getList();
    },
    getList() {
      const api = `${process.env.VUE_APP_PATH}/notifactions/list`;
      this.axios.post(api, this.listRequest)
        .then((res) => {
          if (res.data.status_code === 'SYSTEM_1000') {
            this.data = res.data.data;
            this.total = res.data.total;
          } else {
            const msg = checkStatusCode(res.data.status_code);
            this.$toastError(msg);
          }
        }).catch(error => {
          console.log('Error:', error);
        });
    },
    sortList(column) {
      if (this.listRequest.order_by === column) {
        this.listRequest.order = this.listRequest.order === 'asc' ? 'desc' : 'asc';
      } else {
        this.listRequest.order_by = column;
        this.listRequest.order = 'asc';
      }
      this.getList();
    },
    isActiveSort(column) {
      return this.listRequest.order_by === column;
    },
    handleSearch() {
      this.listRequest.type = this.selected.type || "";
      this.listRequest.status = this.selected.status || "";
      this.getList();
    },
    updatePage(page) {
      this.listRequest.page = page;
      this.getList();
    },
  },
   watch: {
     'listRequest.search'(newVal, oldVal) {
      if(newVal !== oldVal) {
        this.getList();
      }
    }
  },
}
</script>

<style scoped>
</style>
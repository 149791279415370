<template>
  <Breadcrumbs title="Update" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <div class="d-flex gap-3">
            <div class="select-wrap">
              <multiselect  v-model="selected.status" :options="options.status" :searchable="false" @update:modelValue="statusChange"
                :close-on-select="true" :show-labels="false" placeholder="Status">
              </multiselect>
            </div>
            <button type="button" class="btn btn-primary" @click.prevent="handleSearch">Search</button>
          </div>
          <div>
            <button type="button" @click="showModal('addNew')" class="btn btn-primary">Add New</button>
          </div>
        </div>
        <div class="card">
          <div class="table-responsive">
            <table class="table align-middle">
              <thead>
                <tr>
                  <th scope="col">Index</th>
                  <th scope="col">Version</th>
                  <th scope="col">Device</th>
                  <th scope="col">F/W</th>
                  <th scope="col">Importance</th>
                  <th scope="col">Release Note</th>
                  <th scope="col" @click="sortList('status')" class="cursor-p">
                    <div class="d-flex justify-content-between align-items-center gap-2">
                      <p class="mb-0">Status</p>
                      <div>
                        <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('status') && listRequest.order === 'asc' }"></i>
                        <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('status') && listRequest.order === 'desc' }"></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col" @click="sortList('publish_at')" class="cursor-p">
                    <div class="d-flex justify-content-between align-items-center gap-2">
                      <p class="mb-0">Publish at</p>
                      <div>
                        <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('publish_at') && listRequest.order === 'asc' }"></i>
                        <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('publish_at') && listRequest.order === 'desc' }"></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col" @click="sortList('created_at')" class="cursor-p">
                    <div class="d-flex justify-content-between align-items-center gap-2">
                      <p class="mb-0">Created at</p>
                      <div>
                        <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('created_at') && listRequest.order === 'asc' }"></i>
                        <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('created_at') && listRequest.order === 'desc' }"></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in list" :key="item.id">
                  <td>#{{ item.id }}</td>
                  <td>{{ item.version }}</td>
                  <td>{{ item.device }}</td>
                  <td>{{ item.type }}</td>
                  <td>{{ item.update_type }}</td>
                  <td>{{ item.note }}</td>
                  <td>
                    <span :class="{ 'txt-primary': item.status === 'published' }">{{ $capitalizeFirst(item.status) }}</span>
                  </td>
                  <td>{{ item.publish_at }}</td>
                  <td>{{ item.created_at }}</td>
                  <td>
                    <router-link :to="{ name: 'updateDetail', params: { updateId: item.id } }">Details</router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <Pagination :total="total" :currentPage.sync="listRequest.page" :limit="listRequest.limit" @updatePage="updatePage" />
      </div>
    </div>
  </div>
  <addNew v-if="shown.addNew" ref="addNew" @hide="hideModal('addNew')" @update="getList" />
</template>

<script>
import Modal from "bootstrap/js/dist/modal";
import addNew from '@/components-gc/modal/setting/update/addNew.vue';
import Pagination from '@/components-gc/Pagination.vue';
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
  data() {
    return {
      modals: {},
      list: [],
      listRequest: {
        status: '',
        page: 1,
        limit: 10,
        order_by: "",
        order: "",
      },
      total: 0,
      options: {
        status: ['waiting', 'published']
      },
      selected: {
        status: ''
      },
      shown: {
        addNew: false,
      },
    };
  },
  components: {
    addNew,
    Pagination
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      const api = `${process.env.VUE_APP_PATH}/update/list`;
      this.axios.post(api, this.listRequest)
        .then((res) => {
          if (res.data.status_code === 'SYSTEM_1000') {
            this.list = res.data.data;
            this.total = res.data.total;
          } else {
            const msg = checkStatusCode(res.data.status_code);
            this.$toastError(msg);
          }
        }).catch(error => {
          console.log('Error:', error);
        });
    },
    sortList(column) {
      if (this.listRequest.order_by === column) {
        this.listRequest.order = this.listRequest.order === 'asc' ? 'desc' : 'asc';
      } else {
        this.listRequest.order_by = column;
        this.listRequest.order = 'asc';
      }
      this.getList();
    },
    isActiveSort(column) {
      return this.listRequest.order_by === column;
    },
    handleSearch() {
      this.listRequest.country = this.selected.country || "";
      this.listRequest.state = this.selected.state || "";
      this.listRequest.city = this.selected.city || "";
      this.listRequest.assigned = this.selected.assigned || "";
      this.getList();
    },
    updatePage(page) {
      this.listRequest.page = page;
      this.getList();
    },
    showModal(name) {
      this.shown[name] = true;
      this.$nextTick(() => {
        this.modals[name] = new Modal(this.$refs[name].$el);
        this.modals[name].show();
        this.$refs[name].$el.addEventListener('hidden.bs.modal', () => this.onHidden(name));
      });
    },
    hideModal(name) {
      this.modals[name].hide();
    },
    onHidden(name) {
      this.shown[name] = false;
      this.$refs[name].$el.removeEventListener('hidden.bs.modal', () => this.onHidden(name));
    },
    statusChange(val) {
      this.listRequest.status = val;
    },
  },
};
</script>

const state = {
	accessToken: null,
	memberInfo: {
		firstName: '',
		lastName: '',
		role: '',
		profile: ''
	},
	houseDevice: {
		firstName: '',
		lastName: '',
		houseName: '',
		houseAddress: ''
	},
	permissions: [],
	roleName: ''
};

const mutations = {
  setToken(state, data) {
      state.accessToken = data;
    },
	setMemberInfo(state, data) {
		state.memberInfo = data;
	},
	setHouseDevice(state, data) {
		state.houseDevice = data;
	},
	setPermissions(state, data) {
		state.permissions = data;
	},
	setRoleName(state, data) {
		state.roleName = data;
	}
}

export default {
	namespaced: true,
	state,
	mutations
};
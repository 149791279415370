<template>
  <div class="modal fade" id="addNewModal" tabindex="-1" aria-labelledby="addNewLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <Form @submit="submitForm">
          <div class="modal-header">
            <h1 class="modal-title fs-5">Add New Version</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6 mb-3">
                <label for="date" class="col-form-label fw-semibold">Publish Date and Time</label>
                <Field name="date" :rules="{ required: true }" v-slot="{ field }" id="date">
                  <Datepicker1 class="datepicker-here form-control digits py-0" placeholder="Date"
                    v-model="date" v-bind="field" data-language="en" :min-date="new Date()"></Datepicker1>
                  <ErrorMessage name="date" class="text-danger"></ErrorMessage>
                </Field>
              </div>
              <div class="col-md-3 mb-3">
                <label for="type" class="col-form-label fw-semibold">Version</label>
                <p class="mb-0 text-secondary">{{ version }}</p>
              </div>
              <div class="col-md-3 mb-3">
                <label for="type" class="col-form-label fw-semibold">F/W</label>
                <p class="mb-0 text-secondary">{{ fw }}</p>
              </div>
              <div class="col-md-8 mb-3">
                <label for="type" class="col-form-label fw-semibold">Update Type</label>
                <Field name="type" :rules="{ required: true }" v-slot="{ field }">
                  <multiselect 
                    v-model="selected.type" 
                    :options="options.type" 
                    :custom-label="getOptionLabel"
                    track-by="value"
                    :searchable="false" 
                    :close-on-select="true" 
                    :show-labels="false" 
                    placeholder="type" 
                    v-bind="field"
                  ></multiselect>
                  <ErrorMessage name="type" class="text-danger"></ErrorMessage>
                </Field>
              </div>
              <div class="col-md-12 mb-3">
                <label for="file" class="col-form-label fw-semibold">Upload File</label>
                <Field name="file" type="file" class="form-control" id="file" :rules="{ required: true }" v-model="file" />
                <ErrorMessage name="file" class="text-danger" />
              </div>
              <div class="col-md-12">
                <label for="note" class="col-form-label fw-semibold">Realease Note</label>
                <Field v-model="note" name="note" as="textarea" class="form-control" placeholder="Add text" id="note" />
                <ErrorMessage name="note" class="text-danger" />
              </div>
              <div class="col-md-12">
                <div class="checkbox checkbox-primary">
                  <input id="public-key" type="checkbox" v-model="pk" :true-value="1" :false-value="0">
                  <label for="public-key">Update public key</label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import { checkStatusCode } from '@/methods-gc/statusCode';
import Datepicker1 from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';


export default {
  data() {
    return {
      date: null,
      note: '',
      pk: 0,
      file: null,
      version: '',
      fw:'',
      options: {
        type: [
          { text: 'Force Update', value: 'c' },
          { text: 'Important Update', value: 'h' },
          { text: 'General Update', value: 'l' }
        ],
      },
      selected: {
        type: null,
      },
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    Datepicker1
  },
  watch: {
    file(newFile) {
      if (newFile) {
        this.parseFileContent(newFile);
      }
    }
  },
  methods: {
    getOptionLabel(option) {
      return option.text;
    },
    submitForm() {
      const api = `${process.env.VUE_APP_PATH}/update/create`;
      
      const formData = new FormData();
      formData.append('publish_at', this.formatDate(this.date));
      formData.append('update_type', this.selected.type.value);
      formData.append('pk', this.pk);
      formData.append('note', this.note);
      formData.append('binfile', this.file);

      this.axios.post(api, formData)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.$emit('update');
          this.$emit('hide');
          this.$toastSuccess('Added successfully');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    parseFileContent(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const arrayBuffer = e.target.result;
        const uint8Array = new Uint8Array(arrayBuffer);
        const text = new TextDecoder().decode(uint8Array);
        
        const start = text.indexOf('{"nk_info');
        const end = text.indexOf('\x00', start);
        if (start !== -1 && end !== -1) {
          const extractedContent = text.substring(start, end);
          
          try {
            const jsonContent = JSON.parse(extractedContent);
            this.version = jsonContent.nk_info.fv;
            this.fw = this.checkFileType(jsonContent.nk_info.tp);
          } catch (error) {
            console.error('Error parsing JSON:', error);
          }
        } else {
          console.log('Content not found');
        }
      };
      reader.readAsArrayBuffer(file);
    },
    checkFileType(type) {
      switch (type) {
        case 'gw':
          return 'Gateway';
        case 'gwb':
          return 'Gateway Bootloader';
        case 'kc':
          return 'Katcher Core';
        case 'wb':
          return 'Wifi/Bt';
        case 'nd':
          return 'Node';
        case 'ndb':
          return 'Node Bootloader';
        default:
          return 'Unknown';
    }
  },
    formatDate(date) {
      return date.toISOString();
    }
  },
  emits: ['update', 'hide']
}
</script>